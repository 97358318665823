<template>
  <div class="q-pa-xs">
    <place-object
      v-if="place"
      :data="place"
      class="q-mb-sm"
    />

    <q-card
      v-for="product in items"
      :key="product.id"
      class="q-mb-sm"
    >
      <product-object :data="product" />
    </q-card>

    <div
      v-if="items.length <= 0"
      class="fade-animation text-caption absolute-center text-center"
    >
      {{ $t('No product on that place!') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceContainer',
  props: {
    place: {
      type: Object,
      default () {
        return null
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>
